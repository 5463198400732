<template>
  <div style="margin: 20px">
    <el-card>
      <v-table :fetch="fetchData" name="kphistory" :columns="columns" :need-id="false" :render-filters="false"/>
    </el-card>
  </div>
</template>

<script>
import VTable from '@/components/VTable'
import { getKPHistory } from '@/api/api'
import { getFormatDate } from '@/utils'
export default {
  components: {
    VTable
  },
  data () {
    return {
    }
  },
  computed: {
    columns () {
      return [
        {
          field: 'created_at',
          filter: getFormatDate
        },
        {
          field: 'type',
          filter (value) {
            return value
          },
          items: [
            {
              id: 'export',
              title: 'Формирование выгрузки (xlsx)'
            },
            {
              id: 'print',
              title: 'Формирование печатной формы, доклада (docx)'
            }
          ]
        }
      ]
    }
  },
  methods: {
    fetchData (args) {
      // eslint-disable-next-line camelcase
      const period_id = this.$route.query.period_id
      const params = {
        ...args,
        period_id
      }
      return getKPHistory(params)
    }
  }
}
</script>

<style scoped>

</style>
